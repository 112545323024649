// External packages
import * as React from "react"
import { Box } from "theme-ui"
import { Swiper, SwiperClass, SwiperSlide } from "swiper/react"

// Types
import { ContentfulImageGallery } from "types"

// Components
import { UiGridItem } from "../ui/Contentful"
import { Icon } from "../ui/Icon"
import { Picture } from "../Picture"
import { Image } from "./Image"

// Utilities
import { ContentfulTypes } from "../../../utils/types"

export const ImageGallery: React.FC<{
  data: ContentfulImageGallery
}> = ({ data }) => {
  const swiperRef = React.useRef(null)

  if (!data?.images?.length) return null

  const handleNavigationDisable = (swiper: SwiperClass) => {
    swiper.el.parentElement
      .querySelector(".swiper-button-previous")
      .setAttribute(
        "data-disabled",
        swiperRef.current?.isBeginning === true ? "true" : "false"
      )
    swiper.el.parentElement
      .querySelector(".swiper-button-next")
      .setAttribute(
        "data-disabled",
        swiperRef.current?.isEnd === true ? "true" : "false"
      )
  }

  const handleNavigationPosition = (swiper: SwiperClass) => {
    const hasPicture = swiper.slides.some(
      (slide) => slide.querySelector("picture") !== null
    )

    if (!hasPicture) return

    const height = swiper.slides.reduce((max, slide) => {
      return Math.max(max, slide.querySelector("picture")?.offsetHeight || 0)
    }, 0)

    swiper.el.parentElement
      .querySelectorAll("[class^=swiper-button-]")
      .forEach(
        (el) => ((el as HTMLElement).style.top = Math.round(height / 2) + "px")
      )
  }

  const sideMargins = () => {
    if (
      data?.sideMargins === "0px" ||
      data?.sideMargins === "16px" ||
      data?.sideMargins === "32px"
    ) {
      return data?.sideMargins
    }

    return ["32px", data?.sideMargins]
  }

  return (
    <>
      {data?.desktopLayout === "Slider" ? (
        <Box
          sx={{
            display:
              data?.mobileLayout === "Slider" ? ["none", "block"] : "block",
            position: "relative",
            marginBlockStart: data?.topMargin,
            marginBlockEnd: data?.bottomMargin,
            ".swiper": { marginInline: sideMargins() },
            ".swiper-slide": {
              display: "flex",
              alignItems: "center",
              height: "auto",
            },
          }}
        >
          <Swiper
            slidesPerView={
              data?.gridItems === "2"
                ? 2
                : data?.gridItems === "3"
                ? 3
                : data?.gridItems === "4"
                ? 4
                : 1
            }
            spaceBetween="16px"
            cssMode={true}
            onBeforeInit={(swiper) => (swiperRef.current = swiper)}
            onInit={(swiper) => handleNavigationDisable(swiper)}
            onSlideChange={(swiper) => handleNavigationDisable(swiper)}
            onResize={(swiper) => handleNavigationPosition(swiper)}
          >
            {data?.images.map((item) => (
              <SwiperSlide>
                {item?.file?.contentType?.startsWith("video") ? (
                  <Box
                    sx={{
                      height: "100%",
                      video: {
                        display: "block",
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                      },
                    }}
                  >
                    <video
                      src={item?.file?.url}
                      autoPlay
                      muted={true}
                      loop
                      playsInline
                    >
                      <p>Your browser doesn't support HTML video.</p>
                    </video>
                  </Box>
                ) : (
                  <Image
                    data={{
                      id: item?.id,
                      asset: {
                        description: item?.description,
                        gatsbyImageData: item?.gatsbyImageData,
                        file: {
                          url: item?.file?.url,
                          contentType: item?.file?.contentType,
                        },
                      },
                      showDescription: data?.showDescription,
                      topMargin: "0px",
                      bottomMargin: "0px",
                      internal: {
                        type: typeof ContentfulTypes.ContentfulImageGallery,
                      },
                    }}
                  />
                )}
              </SwiperSlide>
            ))}
          </Swiper>
          <Box
            as="button"
            className="swiper-button-previous"
            sx={{
              cursor: "pointer",
              border: 0,
              backgroundColor: "transparent",
              position: "absolute",
              top: "50%",
              left: sideMargins(),
              transform: "translateY(-50%) translateX(calc(-100% - 16px))",
              zIndex: 1,
              padding: 0,
              "&[data-disabled=true]": {
                display: "none",
              },
            }}
            onClick={() => swiperRef.current?.slidePrev()}
          >
            <Icon name="arrow-left" size={7} />
          </Box>
          <Box
            as="button"
            className="swiper-button-next"
            sx={{
              cursor: "pointer",
              border: 0,
              backgroundColor: "transparent",
              position: "absolute",
              top: "50%",
              right: sideMargins(),
              transform: "translateY(-50%) translateX(calc(100% + 16px))",
              zIndex: 1,
              padding: 0,
              "&[data-disabled=true]": {
                display: "none",
              },
            }}
            onClick={() => swiperRef.current?.slideNext()}
          >
            <Icon name="arrow-right" size={7} />
          </Box>
        </Box>
      ) : data?.desktopLayout === "Horizontal" ? (
        <Box
          sx={{
            display:
              data?.mobileLayout === "Slider" ? ["none", "grid"] : "grid",
            gridAutoFlow: "dense",
            gridTemplateColumns: `repeat(${data?.gridItems}, 1fr)`,
            gridTemplateRows: "1fr",
            columnGap: 4,
            rowGap: 4,
            alignItems: "flex-start",
            paddingInline: sideMargins(),
          }}
        >
          {data?.images.map((item) =>
            item?.file?.contentType?.startsWith("video") ? (
              <Box
                sx={{
                  height: "100%",
                  video: {
                    display: "block",
                    width: "100%",
                    height: "100%",
                    objectFit: "cover",
                  },
                }}
              >
                <video
                  src={item?.file?.url}
                  autoPlay
                  muted={true}
                  loop
                  playsInline
                >
                  <p>Your browser doesn't support HTML video.</p>
                </video>
              </Box>
            ) : (
              <Picture
                sources={item?.gatsbyImageData?.images?.sources}
                imageProps={{
                  width: item?.gatsbyImageData?.width,
                  height: item?.gatsbyImageData?.height,
                  sizes: item?.gatsbyImageData?.images?.fallback?.sizes,
                  src: item?.gatsbyImageData?.images?.fallback?.src,
                  srcSet: item?.gatsbyImageData?.images?.fallback?.srcSet,
                  alt: item?.description,
                }}
              />
            )
          )}
        </Box>
      ) : data?.desktopLayout === "Vertical" ? (
        <Box
          sx={{
            display:
              data?.mobileLayout === "Slider" ? ["none", "flex"] : "flex",
            flexDirection: "column",
            rowGap: 4,
          }}
        >
          {data?.images.map((item) =>
            item?.file?.contentType?.startsWith("video") ? (
              <Box
                sx={{
                  height: "100%",
                  video: {
                    display: "block",
                    width: "100%",
                    height: "100%",
                    objectFit: "cover",
                  },
                }}
              >
                <video
                  src={item?.file?.url}
                  autoPlay
                  muted={true}
                  loop
                  playsInline
                >
                  <p>Your browser doesn't support HTML video.</p>
                </video>
              </Box>
            ) : (
              <Picture
                sources={item?.gatsbyImageData?.images?.sources}
                imageProps={{
                  width: item?.gatsbyImageData?.width,
                  height: item?.gatsbyImageData?.height,
                  sizes: item?.gatsbyImageData?.images?.fallback?.sizes,
                  src: item?.gatsbyImageData?.images?.fallback?.src,
                  srcSet: item?.gatsbyImageData?.images?.fallback?.srcSet,
                  alt: item?.description,
                }}
              />
            )
          )}
        </Box>
      ) : null}
      {data?.mobileLayout === "Slider" ? (
        <Box
          sx={{
            display: ["flex", "none"],
            gridAutoFlow: "dense",
            gridTemplateColumns: `repeat(${data?.gridItems}, 1fr)`,
            gridTemplateRows: "1fr",
            columnGap: 4,
            rowGap: 4,
            overflowX: "scroll",
            paddingInline: sideMargins(),
            scrollbarWidth: "none",
            "::-webkit-scrollbar": {
              display: "none",
            },
          }}
        >
          {data?.images.map((item, index) => (
            <UiGridItem
              index={index}
              gridItems={data?.gridItems}
              mobileLayout="Slider"
            >
              {item?.file?.contentType?.startsWith("video") ? (
                <Box
                  sx={{
                    height: "100%",
                    video: {
                      display: "block",
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                    },
                  }}
                >
                  <video
                    src={item?.file?.url}
                    autoPlay
                    muted={true}
                    loop
                    playsInline
                  >
                    <p>Your browser doesn't support HTML video.</p>
                  </video>
                </Box>
              ) : (
                <Image
                  data={{
                    id: item?.id,
                    asset: {
                      description: item?.description,
                      gatsbyImageData: item?.gatsbyImageData,
                      file: {
                        url: item?.file?.url,
                        contentType: item?.file?.contentType,
                      },
                    },
                    showDescription: data?.showDescription,
                    topMargin: "0px",
                    bottomMargin: "0px",
                    internal: {
                      type: typeof ContentfulTypes.ContentfulImageGallery,
                    },
                  }}
                />
              )}
            </UiGridItem>
          ))}
        </Box>
      ) : null}
    </>
  )
}
